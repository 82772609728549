import axios from "axios";
export class HttpService {
    static getHeaderAndURL = () => {
       
        // let airportUrl = "https://aks-cluster-agc.ethiopianairlines.com/ethiopianpartnershome/GetAirportMasterData"; // Agency Portal Cert
        let airportUrl = "https://partners.ethiopianairlines.com/ethiopianpartnershome/GetAirportMasterData"; // Agency Portal Prod

        // Test URL -- to be uncommented for local testing
        // let baseUrl = "https://aks-cluster-dev.ethiopianairlines.com/";

        // Production URL
       let baseUrl = "https://m.ethiopianairlines.com/";

        // Local
       //let baseUrl = "https://localhost:4000/";

        let token = localStorage.getItem("holidaysToken");
        let headers = {
            Authorization: "Bearer " + token, //the token is a variable which holds the token
            //IsDevelopment: true,
            ContentType: "application/json",
            Accept: "application/json",
        };
        return {
            baseUrl: baseUrl,
            token: token,
            headers: headers,
            airportUrl:airportUrl
        };
    };
    //static  getAirportList= ()=> {
    //    let headerAndURL = this.getHeaderAndURL();
    //    return axios.get(headerAndURL.airportUrl ,{headers:headerAndURL.headers});
    //}

    /*
    Note:
    *-etholidays APIs source code is found at https://ethiopianairlines.visualstudio.com/Market%20Place/_git/K8S
    E.g. flightapi-etholidays/v1.0/api, packageapi-etholidays/V1.0/api

    *-marketplace APIs source is found at https://ethiopianairlines.visualstudio.com/Market%20Place/_git/MarketPlace_K8s

    */
    static getService = (relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.baseUrl + "flightapi-etholidays/V1.0/api/";
        return axios.get(baseUrl + relativePath + queryString, {
            headers: headerAndURL.headers,
        });
    };
    static postService = (requestData, relativePath, queryString = "") => {

        //debugger
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.baseUrl + "flightapi-etholidays/V1.0/api/";
        return axios.post(baseUrl + relativePath + queryString, requestData, {
            headers: headerAndURL.headers,
        });
    };

    static getServiceHotel = (relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.baseUrl + "hotelapi-etholidays/V1.0/api/";
        return axios.get(baseUrl + relativePath + queryString, {
            headers: headerAndURL.headers,
        });
    };
    static postServiceHotel = (requestData, relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.baseUrl + "hotelapi-etholidays/V1.0/api/";
        return axios.post(baseUrl + relativePath + queryString, requestData, {
            headers: headerAndURL.headers,
        });
    };
    static getServicePackageBooking = (relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.baseUrl + "packageapi-etholidays/V1.0/";
        return axios.get(baseUrl + relativePath + queryString, {
            headers: headerAndURL.headers,
        });
    };

    static postServicePackageBooking = (
        requestData,
        relativePath,
        queryString = ""
    ) => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.baseUrl + "packageapi-etholidays/V1.0";
        localStorage.setItem("select", JSON.stringify(headerAndURL));
        return axios.post(baseUrl + relativePath + queryString, requestData, {
            headers: headerAndURL.headers,
        });
    };

    static getServicePackage = (relativePath, queryString = "") => {
        
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.baseUrl + "packageapi-marketplace/V1.0/";
      return axios.get(baseUrl + relativePath + queryString, {
            headers: headerAndURL.headers,
        });
    };
    
    static postServicePackage = (requestData, relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.baseUrl + "packageapi-marketplace/V1.0/";
        return axios.post(baseUrl + relativePath + queryString, requestData, {
            headers: headerAndURL.headers,
        });
    };

    static getServiceDefualt = (relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.baseUrl + "paymentapi-etholidays/V1.0/";
        return axios.get(baseUrl + relativePath + queryString, {
            headers: headerAndURL.headers,
        });
    };
    static postServiceDefualt = (requestData, relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.baseUrl + "paymentapi-etholidays/V1.0/";
        return axios.post(baseUrl + relativePath + queryString, requestData, {
            headers: headerAndURL.headers,
        });
    };

    static getServicePayment = (relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.baseUrl + "masterdataapi-etholidays/";
        return axios.get(baseUrl + relativePath + queryString, {
            headers: headerAndURL.headers,
        });
    };

    static postServicePayment = (requestData, relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.baseUrl + "masterdataapi-etholidays/";
        return axios.post(baseUrl + relativePath + queryString, requestData, {
            headers: headerAndURL.headers,
        });
    };

    static postServiceHoldResr = () => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl =
            headerAndURL.baseUrl + "flightapi-etholidays/V1.0/api/Purchase/HoldBNPL/";
        let holdOp = "BNPL_24Hrs" //production
        if ((window.location.hostname.includes("localhost")) || (window.location.hostname.includes("test"))) {
            holdOp = "BNPL_48Hrs" //for test
        }
        var requestData = {
            paymentRequired: false,
            payment: null,
            holdOptionId: holdOp,
            cookieSabreDataRequest: {
                sessionId: localStorage.getItem("sessionId"),
            },
        };

        return axios.post(baseUrl, requestData, {
            headers: headerAndURL.headers,
        });
    };

    // static getIPService = (relativePath, queryString = "") => {
    //   let token = this.getToken();
    //   let baseUrl = "https://ipapi.co";
    //   return axios.get(baseUrl + relativePath + queryString, {
    //     headers: {
    //       Authorization: "Bearer " + token, //the token is a variable which holds the token
    //       //Isdevelopment: "true",
    //       Isdevelopment: "false",
    //       ContentType: "application/json",
    //       Accept: "application/json",
    //     },
    //   });
    // };

    static getIPService = (ipData) => {
        const publicIp = require("public-ip");
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.baseUrl + "masterdataapi-etholidays/api/";

        var relativePath2 = "IpDetail/Get?IpAddress=" + ipData;

        return axios.get(baseUrl + relativePath2, {
            headers: headerAndURL.headers,
        });
    };
    static getCookie = (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    static getToken = () => {

        let xsrfToken = this.getCookie('XSRF-TOKEN');   //*get AntiforgeryToken from Cookies
        return axios.get("Home/Index", {
            headers: {
                "Holiday-X-CSRF-TOKEN": xsrfToken
            }
        });
    };
    static postEmail = (requestData) => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl =
            headerAndURL.baseUrl + "masterdataapi-etholidays/api/Subscription/add/";

        return axios.post(baseUrl, requestData, {
            headers: {
                headers: headerAndURL.headers,
            },
        });
    };
    static getFlightInformation = () => {
        let headerAndURL = this.getHeaderAndURL();

        let baseUrl =
            headerAndURL.baseUrl +
            "flightapi-etholidays/V1.0/api/BrandFare/List?memoryCacheRefresh=false";
        return axios.get(baseUrl, {
            headers: {
                headers: headerAndURL.headers,
            },
        });
    };
    static postServiceTripPlanner = (requestData) => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl =
            headerAndURL.baseUrl + "masterdataapi-etholidays/api/TripPlanner/Add/";
        return axios.post(baseUrl, requestData, {
            headers: headerAndURL.headers,
        });
    };

    static checkToken() {
 
        const token = localStorage.getItem("holidaysToken");

        if (token == null || token == "null") {
            return this.getToken().then((response) => {
                let newToken = response.data.token;
                localStorage.setItem("holidaysToken", newToken);
            });
        } else {

            // TODO
            /*
             * use token from local storage
             * check if token is expired
             * If expired, get new token and update local storage.
            */
            
            return this.getToken().then((response) => {
                let newToken = response.data.token;
                localStorage.setItem("holidaysToken", newToken);
            });
        }
    }
    static getFareRule(sessionId) {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.baseUrl + "/flightapi-etholidays/V1.0/api/BrandFare/FareCompare?SessionId=" + sessionId;
        return axios.get(baseUrl, {
            headers: headerAndURL.headers,
        });
    }
}
