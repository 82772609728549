import React, { Component } from 'react';
import './commentAndComplains.css';

import { HttpService } from "../../../services/http/http-service";

import { Modal, Alert, Card, Spinner, Form, InputGroup, Label, Row, Col, Button, CardGroup, CardDeck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// export const faCalendar: IconDefinition;
import { faBox, faMapSigns, faUser, faClock, faCreditCard, faMap, faLocationArrow, faCalendar, faFighterJet, faCaretDown, faMapMarkedAlt, faMailBulk } from '@fortawesome/free-solid-svg-icons'
// #8262	comments and complaints

class CommentAndComplaints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      isLoadingPayment: false,
      showModal: false,
      FirstName: null,
      LastName: null,
      Email: null,
      Catagory: null,
      Comment: null,
      responseMessage: null
    }
  }


  handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ responseMessage: "Error: Please provide valid Inputes!" });


    }
    else {
      event.preventDefault();
      event.stopPropagation();
      this.submitPayment();
    }

    this.setState({ validated: true })
    //   setValidated(true);
  };


  submitPayment() {

    this.setState({ isLoadingPayment: true })
    let submitApiRelativePath = "api/FeedBack/add";

    if (true) {
      var paymentInformationRequestObject = {
        "FirstName": this.state.FirstName,
        "LastName": this.state.LastName,
        "Email": this.state.Email,
        "Catagory": this.state.Catagory,
        "Comment": this.state.Comment
      };

      HttpService.postServicePayment(paymentInformationRequestObject, submitApiRelativePath)
        .then((response) => {
          this.setState({ isLoadingPayment: false });
          let paymentApiResponse = response.data;
          if (paymentApiResponse.statusAPI == "SUCCESS" || paymentApiResponse.statusAPI == "Success") {
            this.setState({ showModal: true, responseMessage: "You have seccessfully sent a feedback, Thankyou!" });
          }
          else {
            this.setState({ responseMessage: paymentApiResponse.messageAPI });

          }
        },
          error => {

            this.setState({ isLoadingPayment: false })
          });
    }


  }


  render() {

    var { validated } = this.state;

    return (
      <div className="container p-2 pt-2">

        <Form noValidate validated={validated} onSubmit={this.handleSubmit} className=" shadow pl-5 pr-5 pb-3  pt-2 mb-5 bg-white rounded">

          <Card.Header className="d-flex p-0 m-2  bg-white  success" >
            <h4 className="pt-0 float-left">Comment and Complaints</h4>
          </Card.Header>
          {/* <Form.Row style={{ textAlign: "left", color: "yellow" }} className="d-flex p-0 m-2 pl-3 success" style={{}} variant="success">

            {(this.state.isLoadingPayment === false) &&
              <>
                {this.state.responseMessage &&
                  <p >{this.state.responseMessage}</p>
                }
              </>
            }
          </Form.Row> */}

          {((this.state.responseMessage) && (this.state.isLoadingPayment === false)) &&
            <Alert variant="danger">
              {this.state.responseMessage}

            </Alert>
          }



          {/* <br /> */}
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="FirstName">
              {/* <Form.Label className="d-flex">First Name*</Form.Label> */}
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrependFirstName">
                    <FontAwesomeIcon className="pr-2" icon={faUser} size="lg" /></InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  required
                  minLength="2"
                  aria-describedby="inputGroupPrependFirstName"
                  type="text"
                  placeholder="First name *"
                  defaultValue=""
                  value={this.state.FirstName}
                  onChange={(e) =>
                    this.setState({ FirstName: e.target.value })
                  }

                />
                <Form.Control.Feedback type="invalid">
                  Please input a valid First Name
</Form.Control.Feedback>

              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="LastName">
              {/* <Form.Label className="d-flex">Last Name*</Form.Label> */}
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrependLastName">
                    <FontAwesomeIcon className="pr-2" icon={faUser} size="lg" /></InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  required
                  minLength="2"
                  type="text"
                  placeholder="Last name*"
                  aria-describedby="inputGroupPrependLastName"
                  defaultValue=""
                  value={this.state.LastName}
                  onChange={(e) =>
                    this.setState({ LastName: e.target.value })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please input a valid Last Name
</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="Email">
              {/* <Form.Label className="d-flex">Email</Form.Label> */}

              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrependEmail">
                    <FontAwesomeIcon className="pr-2" icon={faMailBulk} size="lg" /></InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control required
                  minLength="3"
                  type="email"
                  placeholder="Enter Email*"
                  aria-describedby="inputGroupPrependEmail"
                  defaultValue=""
                  value={this.state.Email}
                  onChange={(e) =>
                    this.setState({ Email: e.target.value })
                  }

                >
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please provide a valid Email.
</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} controlId="Catagory">
              {/* <Form.Label className="d-flex">Catagory</Form.Label> */}
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrependCatagory">
                    <FontAwesomeIcon className="pr-2" icon={faCreditCard} size="lg" /></InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select" defaultValue=""
                  value={this.state.CardType}
                  required
                  aria-describedby="inputGroupPrependCatagory"
                  onChange={(e) =>
                    this.setState({ Catagory: e.target.value })
                  }
                  onSelect={(value) => this.setState({ Catagory: value })}

                >

                  <option value="" selected>select a catagory*</option>
                  <option value="Booking" >Booking</option>
                  <option value="Hotel Availability">Hotel Availability</option>
                  <option value="Ticketing">Ticketing</option>
                  <option value="Follow up on pending Enquiry">Follow up on pending Enquiry</option>
                  <option value="Special request">Special request</option>
                  <option value="Payment">Payment</option>
                  <option value="Other">Other</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select a catagory
</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

          </Form.Row>
          <Form.Row>

            <Form.Group as={Col} md="10" controlId="ExpireMonth">
              <Form.Label className="d-flex">Comment</Form.Label>

              <InputGroup>
                <InputGroup.Prepend>
                  {/* <InputGroup.Text id="inputGroupPrependExpireMonth">
                    <FontAwesomeIcon className="pr-2" icon={faClock} size="lg" /></InputGroup.Text> */}
                </InputGroup.Prepend>

                <Form.Control
                  as="textarea" rows="3"
                  required
                  minLength="3"
                  placeholder="Place Your Comment Here"
                  aria-describedby="inputGroupPrependLastName"
                  defaultValue=""
                  value={this.state.Comment}
                  onChange={(e) =>
                    this.setState({ Comment: e.target.value })
                  }

                >
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please Write Your Comment.
</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>


          </Form.Row>

          <Row className="pl-5">
            <Col style={{ textAlign: "left" }} sm={11}>
              {this.state.isLoadingPayment &&

                <Button variant="success" disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                                Submit...
                                </Button>
              }

              {(this.state.isLoadingPayment === false) &&
                <Button type="submit" className="btn btn-success btn-sm" variant="success"><strong className='pr-5 pl-5 '>Submit</strong></Button>

              }
            </Col>
          </Row>
        </Form>


        <Modal
          scrollable={true}
          size="lg"
          aria-labelledby="example-custom-modal-styling-title"
          centered
          show={this.state.showModal} onHide={() => { this.setState({ showModal: false }); window.location.reload(); }}>
          <Modal.Header closeButton className="p-1 pl-3 pt-0">
            <Modal.Title >Comment and Complaints</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="success">
              {this.state.responseMessage}

            </Alert>
          </Modal.Body>
        </Modal>
      </div >

    );
  }
}

export default CommentAndComplaints;

//   render(<FormExample />);