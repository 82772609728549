import React, { Component } from "react";
import "./package-details.css";
import { Modal, Button,OverlayTrigger,Tooltip, Card, Accordion, Row, Col } from "react-bootstrap"; 
import queryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from 'html-react-parser';
import {
  faCheckCircle,
  faTimesCircle,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { HttpService } from "../../../../services/http/http-service";
import TermsAndCondition from "../../../shared/termsAndCondition";
import { DataService } from "../../../../services/shared-services/dataServices";
import GallaryImagesSlide from "../../../shared/gallary/GallaryImagesSlide";
import MataDecorator from "../../../shared/meta-decorator/meta-helmet";
import SocialShare from "../../../shared/social-share/sharebuttons";
// #8226	Bug	"Inquire button should be a bit larger
import { isBrowser,
  isMobile
} from "react-device-detect";
// import { Parallax  } from 'react-parallax';
import Figure from 'react-bootstrap/Figure'
import ProgressLoader from "../../../shared/progress-loading/progress-loader";
const { htmlToText } = require('html-to-text');
///Details being logged to console

class PackageDetails extends Component {
  
  constructor(props) {
    super(props);
  
    let currentpageURL=window.location.href 
    if (this.props.packageDetails) {
      this.state = { 
        pageURL:currentpageURL,
        items: this.props.packageDetails,
        // items: { packagesList: [{ price: [], excludes: [], includes: [], package: this.props.packageDetails.package, itinerariesList: [] }] },
        isLoaded: true,
        search: null,
        enquire: false,
      };
    } else {
      let search = queryString.parse(this.props.location.search);
      this.state = { 
        pageURL:currentpageURL,
        items: { package: null, itinerariesList: [] },
        isLoaded: false,
        search: search,
        enquire: true,
      };
    }
  }

  async componentDidMount() {
    if (!this.props.packageDetails) {
      this.initPackageDetails();
    }
  }

  parseTime = (minute) => {
    let hours = parseInt(minute / 60);
    let minutes = minute % 60;
    return <span>{hours + "hrs " + minutes + "mins"}</span>;
  };

  parseDate = (dateString) => {
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
    };
    let date = new Date(dateString);
    let newDate = date.toLocaleString("en-GB", options);
    return <span>{newDate}</span>;
  };

  showEnquire = () => {
    let search =
      "?packageName=" +
      this.state.items.package.name +
      "&packageId=" +
      this.state.items.package.id;
    let path = "/package-enquire" + search;
    window.location.assign(path);
  };

  initPackageDetails() {
    var { isLoaded, items, search } = this.state;

    var relativePath2 = "/api/Packages/ByName?Name=" + search.packageName;

    HttpService.getServicePackage(relativePath2)
      .then((response) => {
        let responseData = response.data;
        // console.log("pack data",responseData)
        this.setState({
          items: responseData.packagesList[0],
          isLoaded: true,
        }); 

        let description=responseData.packagesList[0].package.description;
        let cancellationPolicy=responseData.packagesList[0].package.cancellationPolicy;
        let contactDetailOnArrival=responseData.packagesList[0].package.contactDetailOnArrival;
        let specialNote=responseData.packagesList[0].package.specialNote; 

        // console.log("package :",responseData.packagesList[0])
     
        if( cancellationPolicy){
          // console.log("cancellationPolicy:", cancellationPolicy)
 document.getElementById("cancellationPolicy").innerHTML = cancellationPolicy.toString();
     
        }
        if( contactDetailOnArrival){
          // console.log("contactDetailOnArrival:", contactDetailOnArrival)
 document.getElementById("contactDetailOnArrival").innerHTML = contactDetailOnArrival.toString();
      
        }
        if( description){
          // console.log("description:", description) 
 document.getElementById("package_description").innerHTML =  description.toString();
    
        }   
          // console.log("specialNote:", specialNote)
          if( specialNote){
 document.getElementById("specialNote").innerHTML =  specialNote.toString();
     
        }
      })
      .catch((error) => {
        // console.log("responseData error2 ");
      });
  }

 


  render() {
    var { isLoaded, items, search } = this.state;
    if (!isLoaded) {
      return ( 
          <div class="text-center"> 
          <ProgressLoader displayMess= {"Loading Package" +search.packageTypeName} />
 
          </div> 
      );
    } else {
      let packageDetails = items;
      return (
        <div className=""> 
        <MataDecorator 
         metaInfo={
           { 
        title:"Ethiopian Holidays | Packages",//packageDetails.package.name,
        description:"Enjoy various offer packages with Ethiopian Holidays by planning and booking your vacation. Enjoy an unforgettable journey with the best African airlines.",//htmlToText(packageDetails.package.description),
        image:packageDetails.package.imagePath,
        url:this.state.pageURL,
        twitterCard:packageDetails.package.imagePath,
        imageAlt:packageDetails.package.name,
            }
          }  
        /> 
  
            <Figure>
              <Figure.Image 
                alt={packageDetails.package.caption}
                src={packageDetails.package.imagePath}
              />
              <Figure.Caption> 
                  <>   {packageDetails.package.caption&&
                        <>
            {isMobile&& 
            <span  className={" float-left bottom btnHolidayslabelMobile"} >
              { packageDetails.package.caption }
              {/* Tiya stelae field and Adadi Mariam Rock Hewn Church */}
            </span>
            }
            {isBrowser&& 
            <span  className={" float-left bottom btnHolidayslabeldesktop"}  >
              { packageDetails.package.caption } 
            </span>
            }
            </>
            }
            </>
              </Figure.Caption>
            </Figure>

       
     
          <div className="container pt-3 ">
            <h1 className="text-center pt-0 mb-100">
              {packageDetails.package.name}
            </h1>
 
            <>
              <Row>
                <Col sm={8}> 
                    <Card className="pb-0 shadow-sm text-justify" >
                      <Card.Header className="d-flex success">
                        Overview
                      </Card.Header>

                      <Card.Body>
                        <Card.Title>Overview</Card.Title>
                        <Card.Text  >
                          {/* {htmlToText(packageDetails.package.description)}  */}
                          
                         { packageDetails.package.description&& <div 
            // style={{ textAlign: "left" }}
            // id="package_description"
            className="m-2"
                >

              {parse(packageDetails.package.description)}
              </div>}
                        </Card.Text>
                      </Card.Body>
                    </Card> 
                  <br />

                  {packageDetails.itinerariesList[0] && ( 
                      <Card className="pb-0 shadow text-justify">
                        <Card.Header className="d-flex">Activities</Card.Header>

                        <Card.Body>
                          <Card.Title>Activities</Card.Title>

                          <Accordion defaultActiveKey="0">
                            {packageDetails.itinerariesList[0].activities.map(
                              (activity) => (
                                <Card className="d-flex shadow">
                                  <Accordion.Toggle
                                    className="d-flex shadow"
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                    as={Card.Header}
                                    eventKey={activity.day}
                                  >
                                    <span className="pr-2">
                                      {" "}
                                      <FontAwesomeIcon
                                        size="lg"
                                        icon={faCaretDown}
                                      />
                                    </span>{" "}
                                    Day : {activity.day} {activity.name}
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={activity.day}>
                                   {packageDetails.package.description&&  <Card.Body 
                                    >
                                       {parse(activity.description)}
                                    </Card.Body>}
                                  </Accordion.Collapse>
                                </Card>
                              )
                            )}
                          </Accordion>
                        </Card.Body>
                      </Card>
                  
                  )}

                  <br />
                  <ul class="list-group pb-0 shadow text-justify">
                    <li class="list-group-item list-group-item-success card-header">
                      {" "}
                      WHAT'S INCLUDED
                    </li>
                    {packageDetails.includes.map((item) => (<>
                      {item.include&& <li
                        class="list-group-item  "
                        style={{ textAlign: "left" }}
                      >
                        <FontAwesomeIcon 
                          icon={faCheckCircle}
                          size="lg"
                        />{" "}
                         {parse(item.include)}
                      </li>}</>
                    ))}
                  </ul>
                  <br />
                  <ul class="list-group pb-0 shadow text-justify">
                    <li class="list-group-item list-group-item-success card-header">
                      {" "}
                      WHAT'S EXCLUDED
                    </li>
                    {packageDetails.excludes.map((item) => (<>{item.exclude&&
                      <li
                        class="list-group-item   "
                        style={{ textAlign: "left" }}
                      >
                        <FontAwesomeIcon 
                          icon={faTimesCircle}
                          size="lg"
                        />
                         {parse(item.exclude)}
                      </li>}</>
                    ))}
                  </ul>
                 
          {packageDetails.package.contactDetailOnArrival&&  <>
                  <br />
                  <ul class="list-group pb-0 shadow text-justify">
                    <li class="list-group-item list-group-item-success card-header">
                      {" "}
                      Contact Detail On Arrival
                    </li>  

                    {packageDetails.package.contactDetailOnArrival&&
                    <p  className="m-2 text-justify" >{parse(packageDetails.package.contactDetailOnArrival)}</p>   } 
 
       
                  </ul></>} 
          {packageDetails.package.cancellationPolicy&&  <>
                  <br /> 
                  <ul class="list-group pb-0 shadow text-justify " >
                    <li class="list-group-item list-group-item-success card-header">
                      {" "}
                     Cancellation Policy
                    </li>  
                    <p className="m-2 text-justify"> {parse(packageDetails.package.cancellationPolicy)}</p> 

         
                  </ul></>}
                  
          {packageDetails.package.specialNote&&   <>
                  <br />
                  <ul class="list-group pb-0 shadow text-justify">
                    <li class="list-group-item list-group-item-success card-header">
                      {" "}
                      Special Note
                    </li>  
                    <p  className="m-2 "> {parse(packageDetails.package.specialNote)}</p>
   
                  </ul></>}
                  
                  <br />
                  
                </Col>


                <Col sm={4}> 
                    <Card  className="pb-0 text-center shadow">
                      <Card.Header className="d-flex">
                        <Card.Link
                          onClick={() => this.setState({ showModal: true })}
                          style={{ color: "green", cursor: "pointer" }}
                        >
                          Terms and Condition
                        </Card.Link>
                      </Card.Header>

                      <Card.Body>
                        <Card.Title>
                          {this.state.enquire && (
                            <Button
                              variant="success"
                              size="lg"
                              onClick={() => {
                                this.showEnquire();
                              }}
                            >
                              Enquire
                            </Button>
                          )}
                            <SocialShare 
                            ShareInfo={{
                            title:packageDetails.package.name,
                            description:packageDetails.package.description?parse(packageDetails.package.description):"",
                            image:packageDetails.package.imagePath,
                            url:this.state.pageURL,
                            twitterCard:packageDetails.package.imagePath,
                            imageAlt:packageDetails.package.name,
                              }}
                            
                            /> 
                        </Card.Title>
                      </Card.Body>
                    </Card> 

                  <br />

                  <ul class="list-group shadow">
                    <li class="list-group-item  d-flex justify-content-between align-items-center card-header ">
                      <h4>Highlights </h4>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Tour Name
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip > <strong>{packageDetails.package.name}</strong>.
                          </Tooltip>}
                      >
                                          <span class="badge badge-pill">
                                            {packageDetails.package.name}
                                          </span> 
                      </OverlayTrigger>
                    </li>
                    
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Country
                      <span class="badge badge-pill">
                        {" "}
                        {DataService.getFullCityName(
                          packageDetails.package.destination
                        )}
                      </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Validity Start
                      <span class="badge badge-pill">
                        {" "}
                        {this.parseDate(
                          packageDetails.package.packageValidStartDate
                        )}
                      </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Validity End
                      <span class="badge badge-pill">
                        {" "}
                        {this.parseDate(
                          packageDetails.package.packageValidEndDate
                        )}
                      </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Duration
                      <span class="badge badge-pill">
                        {packageDetails.package.numberOfDays} Day(s)
                      </span>
                    </li>
                  </ul>

                  <br />

                  <ul class="list-group shadow">
                    <li class="list-group-item  d-flex justify-content-between align-items-center card-header ">
                      <h4>Price </h4>
                    </li>
                    {packageDetails.price.length >= 1 && (
                      <>
                        <li class="list-group-item list-group-item-success d-flex justify-content-between align-items-center  ">
                          <span class="badge  badge-pill">Price Group</span>

                          <span class="badge  badge-pill">
                            Price Per Person
                          </span>
                        </li>
                        {packageDetails.price.map((pricetable) => (
                          <li class="list-group-item d-flex justify-content-between align-items-center">
                           <span > {pricetable.min} {(pricetable.max!=pricetable.min)&&  <span > to  {pricetable.max}</span>}</span>
                            <span class="badge badge-secondary badge-pill">
                              {pricetable.currency.code} {pricetable.amount}
                            </span>
                          </li>
                        ))}
                      </>
                    )}
                    {packageDetails.price.amount && (
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Per Adult
                        <span class="badge badge-secondary badge-pill">
                          {packageDetails.price.currency.code}{" "}
                          {packageDetails.price.amount}
                        </span>
                      </li>
                    )}
                  </ul>
                </Col>
              </Row>
            </>
          </div>

          <Modal
            scrollable={true}
            size="lg"
            aria-labelledby="example-custom-modal-styling-title"
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
          >
            <Modal.Header closeButton className="p-1 pl-3 pt-0">
              <Modal.Title>Terms and Condition</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TermsAndCondition></TermsAndCondition>
            </Modal.Body>
          </Modal>
        </div>
      );
    }
  }
}

export default PackageDetails;
